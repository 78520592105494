export default [
  {
    title: '',
    content:
      'Mais de 30 anos de experiência em áreas de Tecnologia, sendo 15 anos em Computação Gráfica, Visualização Científica e Game Engines, com foco em prototipagem, simulações, modelagem 3D e animação de personagens. Coordenou o desenvolvimento de diversos projetos no meio acadêmico e empresarial.',
    customerName: 'José Roberto Andrade',
    customerTitle: 'CEO',
    customerImage: 'https://media-exp1.licdn.com/dms/image/C5603AQGVc7MslF-1IQ/profile-displayphoto-shrink_200_200/0/1516905103381?e=1658966400&v=beta&t=Siel3t00soxxf9buiE8xGcDlRdaWY1nUyIvY16pw9EI'
  },
  {
    title: '',
    content:
      'Cientista da Computação e Doutorando em Inteligência Artificial, possui experiência acadêmica e empresarial.  Como pesquisador, gerente de projetos ou arquiteto de TI, sempre esteve envolvido com as novidades tecnológicas para atender as demandas do mercado.',
    customerName: 'Lucas de Andrade',
    customerTitle: 'Líder de TI',
    customerImage: 'https://media-exp1.licdn.com/dms/image/C4E03AQHzpOFbRCOJxg/profile-displayphoto-shrink_200_200/0/1646334633364?e=1658966400&v=beta&t=wnIy6U3zcL_ruklhaZU3hvF8Wg9PSOlkmrdF8V4-OHA'
  },
  {
    title: '',
    content:
      'Com formação em Engenharia e Música, é especialista em design e desenvolvimento de projetos gamificados incluindo as áreas de programação, modelagem e produção de áudio. Possui vasta experiência em modelagem, impressão 3D e design gráfico.',
    customerName: 'Rafael Soares',
    customerTitle: 'Senior Designer',
    customerImage: 'https://media-exp1.licdn.com/dms/image/C4E03AQFZmbe_1uiYjg/profile-displayphoto-shrink_200_200/0/1564434461584?e=1658966400&v=beta&t=-iW11mLQrDBIwzipFQ9fxdDmdTdeM2GW_oOpYbWxzVA'
  },
  {
    title: '',
    content:
      'Com experiência em gestão como pequeno empresário na Prataria Bonfim, em Teresópolis, e como Presidente da Objetivo Júnior Consultoria Empresarial, possui formação em Engenharia de Produção e Pós-graduação em Gestão Empresarial.',
    customerName: 'Mateus Bonfim',
    customerTitle: 'Líder Administrativo e Comercial',
    customerImage: 'https://media-exp1.licdn.com/dms/image/C5603AQFnOKw8Jh66lg/profile-displayphoto-shrink_200_200/0/1622045936838?e=1658966400&v=beta&t=OVoBFE__ey36HotOpiR89EwG9eAtc4ZjwHqFaz-hnYo'
  },
  {
    title: '',
    content:
      'Advogado, pós graduado em Direito Civil, Empresarial e em Prática Empresarial Avançada, possui experiência como Diretor Jurídico da Objetivo Júnior Consultoria Empresarial, e nas áreas de processo civil, obrigações e contratos empresarial e societário, bem como em fechamento de acordos.',
    customerName: 'Lucas Bonfim',
    customerTitle: 'Assessor Jurídico',
    customerImage: 'https://media-exp1.licdn.com/dms/image/C4E03AQEOt4afyaY9AA/profile-displayphoto-shrink_200_200/0/1623423687503?e=1658966400&v=beta&t=lGD6jHzf0ViQSL4_-lDLS-Eo3OB9Mreby9NDkzl_pYc'
  }
];
