import React from 'react';
import Button from '../components/Button';
import Card from '../components/Card';
import CustomerCard from '../components/CustomerCard';
import LabelText from '../components/LabelText';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';
import StatsBox from '../components/StatsBox';
import customerData from '../data/customer-data';
import Home from '../images/home.jpg';
import Marketing from '../images/services/marketing.jpg';
import Planejamento from '../images/services/planejamento.jpg';
import Treinamentos from '../images/services/treinamentos.jpg';

const Index = () => (
  <Layout>
    <section className="pt-20 md:pt-40">
      <div className="container mx-auto px-8 lg:flex">
        <div className="text-center lg:text-left lg:w-1/2">
          <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none">
            Oil & Games
          </h1>
          <p className="text-xl lg:text-2xl mt-6 font-light">
            Desenvolvendo estratégias inovadoras em treinamento e capacitação
          </p>
        </div>
        <div className="lg:w-1/2">
          <img src={Home} />
        </div>
      </div>
    </section>

    <section id="services" className="py-20 lg:pb-40 lg:pt-48">
    <div className="container mx-auto text-center">
    <h2 className="text-3xl lg:text-5xl font-semibold">Serviços</h2>
    <SplitSection
      primarySlot={
        <div className="lg:pr-32 xl:pr-48">
          <h3 className="text-3xl font-semibold leading-tight">Treinamentos e Jogos Interativos</h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            O treinamento e a capacitação dos colaboradores de uma empresa através do uso de ferramentas de criação de jogos eletrônicos interativos e personalizados, contribui para a motivação e o interesse dos treinandos.  
            Tais ferramentas permitem a movimentação e visualização de equipamentos e personagens em um ambiente virtual, retratando de forma realista o ambiente de trabalho, enquanto o uso da gamificação permite criar um sistema de recompensas e incentivo ao treinando, na realização de suas tarefas.
          </p>
        </div>
      }
      secondarySlot={<img src={Treinamentos} />}
    />
    <SplitSection
      reverseOrder
      primarySlot={
        <div className="lg:pl-32 xl:pl-48">
          <h3 className="text-3xl font-semibold leading-tight">
            Planejamento Operacional
          </h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
              As ferramentas utilizadas no desenvolvimento de jogos eletrônicos podem se adequar às atividades de planejamento operacional de sua empresa, facilitando a visualização e navegação através dos ambientes operacionais. 
              A interatividade e o manuseio da câmera, através do direcionamento de uma câmera virtual, permitem uma visão tridimensional do ambiente e a movimentação do jogador no cenário criado, contribuindo para a definição ou verificação de tarefas e procedimentos, e para a visualização do posicionamento dos operadores em uma determinada manobra.
          </p>
        </div>
      }
      secondarySlot={<img src={Planejamento} />}
    />
    <SplitSection
      primarySlot={
        <div className="lg:pr-32 xl:pr-48">
          <h3 className="text-3xl font-semibold leading-tight">
            Marketing
          </h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            A divulgação de sua empresa, negócio ou serviço através de um aplicativo, jogo eletrônico ou tour virtual personalizado, pode alavancar seus negócios e permitir a troca de informações com seus clientes, de uma forma atrativa e descontraída.
            O acesso a um ambiente virtual interativo, apresentado em telões em feiras e eventos, em PCs ou notebooks, ou mesmo em dispositivos móveis como tablets e aparelhos celulares, permite a imersão e a movimentação do jogador em um ambiente realista e personalizado.
          </p>
        </div>
      }
      secondarySlot={<img src={Marketing} />}
    />
    </div>
    </section>

    <section id="features" className="py-20 lg:pb-40 lg:pt-48">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl lg:text-5xl font-semibold">Projetos</h2>
        <div className="flex flex-col sm:flex-row sm:-mx-3 mt-12">
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Programa Sea The Future</p>
              <p className="mt-4">
                Programa desenvolvido em parceria entre a Fábrica de Startups Brasil e a Subsea 7, para buscar soluções para alguns desafios da empresa. A Oil & Games foi uma das quatro startups selecionadas nessa primeira etapa (Ideation Week) para dar prosseguimento nas etapas seguintes, com uma proposta de solução para o desafio Como Minimizar Riscos Operacionais nos Projetos da Subsea 7. A solução resultou no desenvolvimento de um produto para a empresa.
              </p>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Treinamento e Capacitação em Operações Offshore</p>
              <p className="mt-4">
                Projeto apresentado como Prova de Conceito (POC) à Subsea 7, representando um cenário interativo de manobras a bordo do navio Seven Sun, da frota da empresa, para a capacitação de colaboradores em Operações Offshore. O projeto está em fase de expansão para a criação de um Programa de Treinamento utilizando interface de games, interatividade e gamificação.
              </p>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Logística e Gerenciamento da Cadeia de Suprimentos</p>
              <p className="mt-4">
                Projeto em desenvolvimento, representando de forma lúdica e interativa as etapas da logística de armazenamento e transporte de mercadorias, matérias-primas e insumos em um galpão de uma empresa. Aspectos relacionados à segurança e aos processos envolvidos são abordados no game, e poderão ser adaptados às necessidades de uma determinada empresa.
              </p>
            </Card>
          </div>
        </div>
      </div>
    </section>

    <section id="stats" className="py-20 lg:pt-32">
      <div className="container mx-auto text-center">
        <LabelText className="text-gray-600">Nossos Números</LabelText>
        <div className="flex flex-col sm:flex-row mt-8 lg:px-24">
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="+100" secondaryText="Players Registrados" />
          </div>
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="4" secondaryText="Projetos Corporativos Entregues" />
          </div>
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="X" secondaryText="Estatística" />
          </div>
        </div>
      </div>
    </section>

    <section id="testimonials" className="py-20 lg:py-40">
      <div className="container mx-auto">
        <LabelText className="mb-8 text-gray-600 text-center">Quem Somos</LabelText>
        <div className="flex flex-col md:flex-row md:-mx-3">
          {customerData.map(customer => (
            <div key={customer.customerName} className="flex-1 px-3">
              <CustomerCard customer={customer} />
            </div>
          ))}
        </div>
      </div>
    </section>

    <section className="container mx-auto my-20 py-24 bg-gray-200 rounded-lg text-center">
      <h3 className="text-5xl font-semibold">Pronto para transformar a sua empresa?</h3>
      <p className="mt-8 text-xl font-light">
        Fornecemos soluções personalizadas através da criação de jogos eletrônicos e tour virtuais, desenvolvidos com a utilização de ferramentas de modelagem gráfica 3D e game engines.
      </p>
      <p className="mt-8">
        <Button size="xl">Fale Conosco</Button>
      </p>
    </section>
  </Layout>
);

export default Index;
